.main {
    margin-top: 60px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.poStyleDiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.textWithInput {
    font-weight: 600;
}

.info {
    border-radius: 5px;
    border: 2px solid var(--mantine-color-gray-8)
}

.infoMain {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
    border-bottom: 2px solid var(--mantine-color-gray-5);
    ;
}

.infoSub {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px;
    border-radius: 5px;
}

.infoTitle {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-8);
    padding: 3px 15px 3px 15px;
    overflow: auto;
    scrollbar-width: none;
    font-weight: 600;
}

.infoText {
    padding: 3px;
    overflow: auto;
    scrollbar-width: none;
    font-weight: 500;
}

.mainTable {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: var(--mantine-color-gray-8); */
}

.tableHead {
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
    font-size: medium;
}
.tableHeadModal{
    background-color: var(--mantine-color-gray-5);
    color: var(--mantine-color-gray-0);
    font-size: medium;
}
.tableData{
    min-width: 50px;
    /* max-width: fit-content; */
}
.tableDataModal{
    min-width: 100px;
    /* max-width: fit-content; */
}

/* .footerDiv {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-9);
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    bottom: 0;
    height: 50px;
    padding: 5px 5px 20px 5px;
    margin-bottom: 10px;
    width:100%;
    position: fixed;
} */

.bottomBtn {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-8);
}

.mainRows{
    background-color: var(--mantine-color-gray-1);
}

.poTable{
    height: 150px;
    overflow: auto;
    scrollbar-width: none;
    border: 1px solid var(--mantine-color-gray-9);
    border-radius: 5px;
}
@media screen and (max-width:768px) {
    .poTable{
        height: 205px;
        overflow: auto;
        scrollbar-width: none;
        border: 1px solid var(--mantine-color-gray-9);
        border-radius: 5px;
    }   
}