.mainGrid{
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 10px;
    width: 60vw;
} 

.container{
    border-radius: 10px;
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.buttonContainer{
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .mainGrid{
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        border-radius: 10px;
        width: 80vw;
    }
}