.main{
    margin-top: 40px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    margin-bottom: 20px;
}

.gridColDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: bold;
    scrollbar-width: none;
}
.gridRowDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
}
.rowDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
    width: 100%;
    padding: 10px 30px 10px 30px; 
}
.textWithInput{
    margin-top: 5px;
    font-weight: 600;
}

.checkboxGroup{
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* width: 100%; */
    padding: 10px 30px 10px 60px;
    /* border-radius: 20px; */
}

.sizeStoreDiv{
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-weight: bold;
    width: 98%;
    margin:auto;
    padding: 10px 30px 10px 60px;
    border-radius: 20px;
}

.buttonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
}