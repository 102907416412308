.main {
    margin-top: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.pagincationIcon {
    border-radius: 15px;
    background-color: var(--mantine-color-gray-8);
}

.pagincationIcon:disabled {
    border-radius: 15px;
    background-color: var(--mantine-color-gray-2);
}

.paginationGroup {
    display: flex;
    gap: 45px;
    flex-direction: row;
    border-radius: 20px;
    /* background-color: var(--mantine-color-gray-3); */
    padding: 5px;
}

.topDiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.textWithInput {
    font-weight: 600;
    padding-top: 5px;
    width: 90px;
}

.gridRowDiv {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
    width: 100%;
}

.selectDiv {
    width: 100%;
}

.tableHead {
    background-color: var(--mantine-color-gray-9);
    color: var(--mantine-color-gray-0);
    font-size: medium;
}

.plusIcon {
    color: var(--mantine-color-gray-0);
    /* background-color: var(--mantine-color-gray-8); */
    background-color: var(--mantine-color-gray-9);
    border: 2px solid var(--mantine-color-gray-0);
    margin-top: 25px;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
    height: 37px;
    width: 37px;
}

.plusIcon:hover {
    color: var(--mantine-color-gray-9);
    background-color: var(--mantine-color-gray-4);
    /* background-color: red; */
    border: 2px solid var(--mantine-color-gray-9);
}

.trashIcon {
    color: #c60000;
    /* background-color: var(--mantine-color-gray-8); */
    /* background-color: red; */
    border: 2px solid #c60000;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
}

.trashIcon:hover {
    color: var(--mantine-color-gray-0);
    background-color: #c60000;
    /* background-color: red; */
    border: 2px solid var(--mantine-color-gray-0);
}

.tableStore {
    height: 45vh;
    overflow: auto;
    scrollbar-width: none;
    margin-top: 15px;
    /* background-color: #640000; */
}

.selectStore {
    flex-grow: 1;
}

.storeSection {
    margin-top: 40px;
    /* background-color: var(--mantine-color-gray-0); */
    border-radius: 15px;
    /* box-shadow: 0px 1px 2px 2px var(--mantine-color-gray-1); */
}

.rowSection {
    display: flex;
    flex-direction: row;
    gap: 15px;
}