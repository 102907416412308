.gridColDiv{
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-weight: bold;
    flex-grow: 1;
    border-radius: 5px;
}
.gridRowDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
}
.textWithInput{
    font-weight: 600;
}

.table {
    border-radius: 5px 5px 5px 5px;
    width: 100%;
}


.stickyDiv{
    position: sticky;
    top: 0;
    z-index: 100;
}
