.UnstyledButton{
    display: block;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 5px;
    background-color: var(--mantine-color-gray-3);
    width: 100%;
    border-radius: 5px;
}
.UnstyledButton:hover {
    background-color: var(--mantine-color-gray-6);
    color: var(--mantine-color-gray-0);
}
