.tableDiv {
    width: 100%;
    height: 78vh;
    overflow-y: auto;
}

.buttonDiv {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.gridSection {
    padding: 10px;
    /* border-radius: 10px; */
    border-bottom: 1px solid var(--mantine-color-gray-2);
    margin-top: 10px;
}

.label {
    background-color: var(--mantine-color-gray-1);
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

.titleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: 10px;
    gap: 20px;
}

.titleBoxText {
    font-size: 18px;
    font-weight: 600;
    padding: 4px;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
}

.titleBoxElement {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.invoice {
    cursor: pointer;
}

.invoice:hover {
    font-weight: 600;
    /* background-color: var(--mantine-color-gray-8); */
    color: var(--mantine-color-gray-8);
    /* border-radius: 5px; */
    border-bottom: 3px solid var(--mantine-color-gray-8);
}