  .navbar {
      position: fixed;
      top: 0;
      left: -250px;
      /* Start off-screen */
      width: 250px;
      height: 100%;
      color: white;
      transition: left 0.3s ease;
      /* Smooth transition for sliding */
      z-index: 1000;
      /* Ensure navbar is on top */
      overflow-y: auto;
      padding: 20px;
  }

  .navbar.open {
      left: 0;
      /* Slide the navbar in */
      margin-top: 62px;
      border: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
      background-color: var(--mantine-color-gray-1);
      color: var(--mantine-color-gray-8);
  }

  .header {
      padding: var(--mantine-spacing-md);
      padding-top: 0;
      margin-left: calc(var(--mantine-spacing-md) * -1);
      margin-right: calc(var(--mantine-spacing-md) * -1);
      color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
      border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }

  .links {
      flex: 1;
      margin-left: calc(var(--mantine-spacing-md) * -1);
      margin-right: calc(var(--mantine-spacing-md) * -1);
  }

  .linksInner {
      padding-top: var(--mantine-spacing-xl);
      padding-bottom: var(--mantine-spacing-xl);
  }

  .footer {
      margin-left: calc(var(--mantine-spacing-md) * -1);
      /* margin-right: calc(var(--mantine-spacing-md) * -1); */
      border-top: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
      bottom: 0;
      /* margin-top: max(0, calc(var(--mantine-spacing-md) * -1)); */
      /* padding: var(--mantine-spacing-md); */
      padding-top: calc(var(--mantine-spacing-md) * 2);
  }

  /* style={{ backgroundColor: '#000000',zIndex: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center', 
    gap: '20px',  position: "fixed", top: "0", width: "100%", height: "40px" }} */
  .navHeader {
      background-color: var(--mantine-color-gray-10);
      height: 62px;
      z-index: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      position: fixed;
      top: 0;
      width: 100%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
  }

  .copyright {
      background-color: black;
      height: 13px;
      padding-top: 12px;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      width: 100%;
  }

  .logo {
      padding: 5px;
      align-items: center;
      display: flex;
      gap: 10px;
      cursor: pointer;
      margin-left: 20px;
      /* margin-top: 10px; */
  }

  .copyright {
      position: "fixed";
      bottom: 0;
      /* width: "100%"; */
      z-index: 10000;
      height: "30px";

  }