.upperSection{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 100%;
}

.table{
    width: 97vw;
    margin-top: 15px;
}
.tableHead{
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
}

.expandedStyleTableHead {
    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-gray-9);
}

.expandedTableHead {
    background-color: var(--mantine-color-gray-4);
    color: var(--mantine-color-gray-0);
}

.downloadIcon{
    cursor: pointer;
    color: var(--mantine-color-gray-8)
}