.gridColDiv {
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-weight: bold;
    flex-grow: 1;
    border-radius: 5px;
    height: 40vh;
    overflow: 'auto';
    scroll-behavior:auto;
}

.gridRowDiv {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
}

.textWithInput {
    font-weight: 600;
}

.table {
    /* border-radius: 5px 5px 5px 5px; */
    width: 95%;
    margin: auto;
}