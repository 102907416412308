.main {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 45vh;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: var(--mantine-color-gray-8); */
}

.tableHead {
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
}

.expandedTableHead {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-9);
}

.topSection {
    display: flex;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: var(--mantine-color-gray-8);
    z-index: 5;
}

.title {
    text-align: center;
    background-color: var(--mantine-color-gray-1);
    border-radius: 10px;
    width: 100%;
}