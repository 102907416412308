.main {
    width: 100%;
    position: sticky;
    top: 100;
    display: flex;
    z-index: 5;
    overflow: scroll;
    scrollbar-width: thin;
    padding-bottom: 30px;
    margin-top: -30px;
    height: 81vh;
}

.table {
    /* border-collapse: collapse; */
    width: 100%;
    /* display: block; */
    overflow-x: scroll;
}

.table th {
    padding: 10px;
    text-size-adjust: 100%;
    /* background: #000000; */
    cursor: col-resize;
    position: sticky;
    top: 0;
    z-index: 10;
}


.table th:hover {

    background: var(--mantine-color-gray-8);
}

.cell {
    /* text-size-adjust: 100%; */

    /* background: #black; */
    color: #000000;
    cursor: default;
    border: 1px solid #000000;
    /* top: 0;
    z-index: 1; */
    /* border: 1.8px solid ; */

    /* height: 60px; */
    input {
        height: 120%;
        width:min-content;
        padding: 10px;
        /* background-color: #black; */
        border: none;
    }
}


.resizer {
    position: absolute;
    right: 0;
    top: 0;
    /* height: 100%; */
    width: 10px;
    /* Width of the resizer area */
    cursor: col-resize;
    /* z-index: 1; */
}

.thead {
    /* width: 100%; */
    height: auto;
    position: sticky;
    /* display: flex;
    justify-content: space-between; */
    z-index: 1;
}

.th {
    background-color: var(--mantine-color-gray-9);
    color: var(--mantine-color-gray-0);
    /* width: 100%; */
    /* border: 1px solid #black; */
    font-weight: 500;
    position: sticky;
    flex-direction: row;
    top: 1;
    position: fixed;
    /* Fixes the element in place */
    top: 20px;
}

.tr {
    /* background-color: blue; */
    width: 100%;
}

.trNew {
    background-color: var(--mantine-color-gray-2);
}

.td {
    /* width: 95%; */
    text-align: center;
    border-width: 2px solid #000000;
    /* height: 40px; */
    /* padding: 2px; */
}

.textInput {
    width: 100%;
    /* height: 100%; */
}

.icon {
    /* color: #; */
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
}

.iconHeader{
    /* color: #black; */
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    width: 20px;
    height: 20px;
}
.iconSmall{
    /* color: #; */
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    width: 13px;
    height: 13px;
}

.icon:hover {
    /* color: #black; */
    background-color: #000000;
    border-radius: 5px 5px 5px 5px;
}

.fileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-start; */
    width: 100%;
}

.fileList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    /* margin-bottom: 20px; */
    gap: 5px;
    margin-left: -12%;
}

.fileListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    padding: 2px;
    /* color: #; */
    font-weight: 500;
    background-color: var(--mantine-color-gray-1);
    border-radius: 35px 35px 35px 35px;
    /* margin-bottom: 20px; */
}


.fileName {
    cursor: pointer;
}

.fileInput {
    width: 66%;
    cursor: pointer;
}


.resizer {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;

}

/* <div style={{ cursor: 'pointer', position: 'sticky', top: 1, zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '10px' }}> */
.fixed {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-9);
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 20px;
    bottom: 0;
    height: 50px;
    padding: 5px 5px 20px 5px;
    width: 100%;
    position: fixed;
    flex-direction: row;
    width: 100%;
    gap: 50px;
}

.selectDropdown {
    /* position: sticky; */
    top: 100%;
    /* Position it just below the cell */
    left: 0;
    /* z-index: 9999; */
    /* Ensure it's above the table */
    /* background-color: white; */
    /* border: 1px solid #ccc; */
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
    /* Add shadow for visibility */
    /* width: max-content; */
    max-height: 200px;
    /* Optional: Limit dropdown height */
    overflow-y: auto;
    /* Make dropdown scrollable if necessary */
}