.table{
    width: 98vw;
    /* height: 80vh; */
}
.tableGrid{
    margin-bottom: 65px;
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.tbody{
    width: 100%;
    /* background-color: rgb(183, 183, 183); */
    color: black;
}
.tableHead{
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
}
.expandedTableHead{
    background-color: var(--mantine-color-gray-5);
    color: var(--mantine-color-gray-0);
}
.icon{
    cursor: pointer;   
}
.mainRows{
    cursor: pointer;
}
.footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding-bottom: 20px;
    padding-top: 10px;
}

.mainGrid{
    padding: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
    border-radius: 10px;
    /* width: 60vw; */
} 

.container{
    border-radius: 10px;
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.buttonContainer{
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .mainGrid{
        padding: 10px;
        /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
        border-radius: 10px;
        width: 80vw;
    }
}