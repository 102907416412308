.main {
    margin-top: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}



.paginationGroup{
    display: flex;
    gap: 45px;
    flex-direction: row;
    border-radius: 20px;
    /* background-color: var(--mantine-color-gray-3); */
    padding: 5px;
}

.pagincationIcon{
    border-radius: 15px;
    background-color: var(--mantine-color-gray-8);
}
.pagincationIcon:disabled{
    border-radius: 15px;
    background-color: var(--mantine-color-gray-2);
}