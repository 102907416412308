.card {
    width: 70%;
    margin: auto;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:760px) {
    .card {
        width: 100%;
        margin: auto;
        align-items: center;
        justify-content: center;
    }   
}
.qrCode {
    margin-top: 10px;
    display: flex;
}

.styleList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.styles {
    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-gray-9);
    padding: 5px;
    border-radius: 10px;
    font-size: medium;
}