.main {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 42vh;
    overflow: auto;
    scrollbar-width: none;
    /* background-color: var(--mantine-color-gray-8); */
}
.table{
    width: 100%;
    height: 35vh;
    overflow: auto;
    scrollbar-width: none;
}

.tableHead{
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
}

.expandedTableHead{
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-9);
}
