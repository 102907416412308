.main {
    margin-top: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.container {
    /* background-color: var(--mantine-color-blue-1); */
    margin-top: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: 94h; */
    /* margin-top: 62px; */
  }
  
  
  .containerMainDiv {
    /* background-color: var(--mantine-color-gray-0); */
    border: 1px solid var(--mantine-color-blue-2);
    margin-top: 20px;
    border-radius: 10px;
    width: 98vw;
    height: 89vh;
    overflow: scroll;
    scrollbar-width: none;
    text-align: center;
  }
  .containerTableDiv {
    /* padding: 10px; */
    padding: 0px 0px 10px 10px;
    height: 82vh;
    width: 97vw;
    margin: auto;
    overflow: scroll;
    scrollbar-width: none;
  }
  
  .containerFooterDiv {
    /* margin-top: 10px; */
    padding: 30px;
    padding: 0px 0px 10px 10px;
    height: 6vh;
    width: 97vw;
    margin: auto;
    overflow: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .headerDiv {
    /* margin-top: 10px; */
    padding: 10px 0px 10px 10px;
    height: 6vh;
    width: 97vw;
    margin: auto;
    overflow: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

.title {
    text-align: center;
    background-color: var(--mantine-color-gray-1);
    border-radius: 10px;
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    margin-top: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tableHead {
    background-color: var(--mantine-color-gray-8);
    color: var(--mantine-color-gray-0);
    font-size: medium;
}

.footerDiv {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-9);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    bottom: 0;
    height: 50px;
    padding: 5px 5px 20px 5px;
    margin-bottom: 10px;
    width: 100%;
    position: fixed;
}

.btnDark {
    color: var(--mantine-color-gray-0);
    background-color: var(--mantine-color-gray-10);
}

.btnDark:hover {
    color: var(--mantine-color-gray-10);
    background-color: var(--mantine-color-gray-1);
    border: 1.5px solid var(--mantine-color-gray-10);
}

.icon {
    color: var(--mantine-color-gray-10);
    cursor: pointer;
}

.icon:hover {
    color: var(--mantine-color-gray-1);
    background-color: var(--mantine-color-gray-10);
    border-radius: 5px;
}

.rowDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.paginationGroup {
    display: flex;
    gap: 45px;
    flex-direction: row;
    border-radius: 20px;
    /* background-color: var(--mantine-color-gray-3); */
    padding: 5px;
}

.pagincationIcon {
    border-radius: 15px;
    background-color: var(--mantine-color-gray-8);
}

.pagincationIcon:disabled {
    border-radius: 15px;
    background-color: var(--mantine-color-gray-2);
}