.main {
    margin-top: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.icon {
    /* color: #; */
    cursor: pointer;
    color: var(--mantine-color-gray-9);
}
.icon:hover {
    /* color: #; */
    cursor: pointer;
    color: var(--mantine-color-gray-0);
    background-color: var(--mantine-color-gray-9);
    border-radius: 15px;
}

.tableHead {
    background-color: var(--mantine-color-gray-9);
    color: var(--mantine-color-gray-0);
    font-size: medium;
}

.tableHeadModal {
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-9);
    font-size: medium;
}

.tableData {
    min-width: 50px;
    /* max-width: fit-content; */
}

.tableDataModal {
    min-width: 100px;
    /* max-width: fit-content; */
}

.pagincationIcon {
    border-radius: 15px;
    background-color: var(--mantine-color-gray-8);
}

.pagincationIcon:disabled {
    border-radius: 15px;
    background-color: var(--mantine-color-gray-2);
}

.paginationGroup {
    display: flex;
    gap: 45px;
    flex-direction: row;
    border-radius: 20px;
    /* background-color: var(--mantine-color-gray-3); */
    padding: 5px;
}